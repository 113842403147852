/* eslint-disable import/no-anonymous-default-export */
export const COLORS = {
  // base colors
  primary: "#004AAC",
  secondary: "#C1EAF8",
  topColor: "#D5E6ED",
  bottomColor: "#C1EAF8",

  // colors
  transparent: "transparent",
  black: "#454545",
  white: "#FFFFFF",
  whiteTransparent: "#FFFFFF80",
  lightGray: "#EAF3F6",
  darkgray: "#505050",
  error: "#FF0000",
  delete: "#860303",
};

export const FONTS = {
  bold: 800,
  medium: 500,
  regular: 300,
  semibold: 600,
};

export default { COLORS, FONTS };
