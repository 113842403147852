/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Drawer, Grid } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useAuthState } from "../providers/AuthProvider";
import client from "../kairosClient";

import RightMenu from "./RightMenu";

import logokairos from "../images/takeflight_logo.png";

function MainHeader() {
  const { lg } = Grid.useBreakpoint();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isLogged, token, user } = useAuthState();
  const [company, setCompany] = React.useState(null);
  const [ setLoading] = React.useState(false);

  React.useEffect(() => {
    // Extract the code company from URL
    const codecompany = new URLSearchParams(window.location.search).get("c");
    if (codecompany) {
      client.company
        .verifyCodeCompany(codecompany)
        .then((res) => res.json())
        .then((res) => {
          console.log("Verified company with code:", res);

          client.company
            .getById(res)
            .then((companyDetails) => companyDetails.json())
            .then((companyDetails) => {
              console.log("Fetched company details:", companyDetails);

              setCompany(companyDetails);
            });
        });
    } else {
      setCompany(null);
    }
  }, [window.location.search]);

  React.useEffect(() => {
    if (user) {
      if (user.companyId) {
        console.log("ID****", user.companyId);
        
        client.company
          .getById(user.companyId, token)
          .then((res) => res.json())
          .then((res) => {
            console.log("MainHeader.useEffect[] instances", res);
            setCompany(res);
          })
          
      }
    } else setCompany(null);
  }, [user?.companyId, token]);

  const showDrawer = () => {
    setIsMenuOpen(true);
  };

  const handleOnClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="menuBar">
      <div className="logo">
        {company ? (
          <a href={"/welcome"}>
            <img
              src={company?.logo}
              className="logo-header-company"
              alt="logo"
            />
          </a>
        ) : (
          <a href={isLogged() ? "/welcome" : "/"}>
            <img src={logokairos} className="logo-header" alt="logo" />
          </a>
        )}
      </div>
      {lg ? (
        <RightMenu />
      ) : (
        <React.Fragment>
          <MenuOutlined
            style={{ fontSize: "30px" }}
            className="barsMenu title-blue"
            onClick={showDrawer}
          >
            <span className="barsBtn"></span>
          </MenuOutlined>
          <Drawer
            placement="right"
            closable={false}
            onClose={handleOnClose}
            visible={isMenuOpen}
          >
            <RightMenu />
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}
export default MainHeader;
