import React, {  useState } from "react";
import { useTranslation } from "react-i18next";
import Analytics from "./Analytics";
import RegistrationCompany from "./RegistrationCompany";

const Admin = () => {
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState("0");

  const submenu = [
    { title: t("label_analytics").toUpperCase(), id: 0 },
    { title: t("label_registrations").toUpperCase(), id: 1 },
  ];

  const selectMenu = (e) => {
    setSelectedMenu(e.target.id);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#E3EEF3",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {submenu.map((c, index) => (
          <span
            key={`categories-${c.id}`}
            className={
              selectedMenu === index + "" ? "submenu-selected" : "submenu"
            }
            onClick={selectMenu}
            id={c.id}
          >
            {c.title}
          </span>
        ))}
      </div>

      {selectedMenu === "0" ? (
        <div>
          <Analytics />
        </div>
      ) : null}
      {selectedMenu === "1" ? <RegistrationCompany /> : null}
    </>
  );
};

export default Admin;
