/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Grid, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuthState } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import BaseButton from "./BaseButton";
import dayjs from "dayjs";
import client from "../kairosClient";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const RightMenu = () => {
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const history = useHistory();
  const { t } = useTranslation();
  const { xl, md } = Grid.useBreakpoint();
  const [company, setCompany] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const { isDoingSurvey, isProfileExpired, logout, token, user, isAdmin } =
    useAuthState();

  React.useEffect(() => {
    if (user) {
      if (user.companyId) {
        console.log("ID****", user.companyId);
        setLoading(true);
        client.company
          .getById(user.companyId, token)
          .then((res) => res.json())
          .then((res) => {
            console.log("MainHeader.useEffect[] instances", res);
            setCompany(res);
          })
          .finally(() => setLoading(false));
      }
    }
  }, [user?.companyId, token]);

  if (!user) {
    return (
      <Menu>
        <Menu.Item key="login">
          <Link to="/login" className="menu-item">
            {t("login").toUpperCase()}
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div
      className="menuCon"
      style={{ marginLeft: "auto", alignItems: "center" }}
    >
      <nav>
        <ul
          className="menu-item responsive-menu"
          style={{
            listStyleType: "none",
          }}
        >
          {user && !user.companyId && (
            <li>
              <Link to="/welcome">{t("label_welcome").toUpperCase()}</Link>
            </li>
          )}

          {user && user?.companyId && user?.companyId != null && (
            <li>
              <a
                href={company.link ? company.link : null}
                target="blank"
                style={{ textTransform: "uppercase" }}
              >
                {company.name}
              </a>
            </li>
          )}

          <li>
            <Link to="/profile">{t("label_survey_profile").toUpperCase()}</Link>
          </li>

          {/*  {user && !user.companyId && (
            <li>
              <Link to="/ebook">{t("label_ebook").toUpperCase()}</Link>
            </li>
          )} */}

          {/*   {user && !user.companyId && (
            <li>
              <Link to="/webinar">{t("label_webinar").toUpperCase()}</Link>
            </li>
          )}
 */}
          {isAdmin() && (
            <li>
              <Link to="/admin">{t("label_admin").toUpperCase()}</Link>
            </li>
          )}

          <li>
            <Link to="/data">{t("label_my_data").toUpperCase()}</Link>
          </li>

          <li>
            <Link to="/" onClick={() => logout()}>
              {t("logout").toUpperCase()}
            </Link>
          </li>
          {isDoingSurvey && (
            <BaseButton
              type="primary"
              shape="round"
              size="large"
              onClick={() => history.push("/surveyresult")}
              style={{
                textTransform: "uppercase",
                marginBottom: "0px",
              }}
            >
              {t("pause")}
            </BaseButton>
          )}
          {!isDoingSurvey &&
            (user.totQuestion !== user.totAnswer || !isProfileExpired()) && (
              <BaseButton
                type="primary"
                shape="round"
                size="large"
                onClick={() => history.push("/survey")}
                style={{
                  textTransform: "uppercase",
                  marginBottom: "0px",
                }}
              >
                {t(
                  user.totAnswer === 0
                    ? "home_survey"
                    : isProfileExpired() || user.profileId
                    ? "home_survey_redo"
                    : "home_continue_survey"
                )}
              </BaseButton>
            )}
        </ul>
      </nav>
    </div>
  );
};

export default RightMenu;
