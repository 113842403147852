import dayjs from "dayjs";

const roles = {
  BASIC: {
    id: 1,
    name: "basic",
  },
  INTERNAL: {
    id: 2,
    name: "internal",
  },
  ADMIN: {
    id: 3,
    name: "admin",
  },
};

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

const work = [
  "Impiegato",
  "Dirigente",
  "Imprenditore",
  "Libero professionista",
  "Docente",
  "Altro",
];

const gender = ["male", "female", "not_binary", "prefer_not_answer"];

const current_year = dayjs().year();

const years = Array.from(Array(80)).map(
  (value, index) => current_year - 80 + index
);

//Mock data
const WEBINARS = [
  {
    id: 1,
    title: "123 Latino",
    poster:
      "https://image.mux.com/02zSWyHtKX1g8lvxMNHtxfS2n00NOKKYBDrMigdJvGPU8/thumbnail.png",
    playback:
      "https://stream.mux.com/02zSWyHtKX1g8lvxMNHtxfS2n00NOKKYBDrMigdJvGPU8.m3u8",
    text: "Il latino e le versioni non saranno più un problema. Chiara Malavasi in questo webinar ci aiuta a orientarci nella materia: insegna efficaci tecniche di analisi della frase e spiega come gestire le informazioni grammaticali e lessicali di un testo, in modo da orientarsi verso la sua comprensione. Infine, si passa alla pratica: la docente dimostra come tradurre un testo dal latino all'italiano. Inziamo?",
  },
  {
    id: 2,
    title: "123 Fisica",
    poster:
      "https://image.mux.com/5dWZaytk00400dYjm5G16tZrkwjcMO1u02AfDkOC802wUlY/thumbnail.png",
    playback:
      "https://stream.mux.com/5dWZaytk00400dYjm5G16tZrkwjcMO1u02AfDkOC802wUlY.m3u8",
    text: "Imparare la fisica è meno difficile di quello che pensi. Grazie a questo webinar tenuto da Francesca Ercolessi, gli studenti acquisiscono un approccio e un metodo efficaci per imparare la materia, sia nelle lezioni in aula sia online. Al termine saranno in grado di capire, impostare e risolvere i problemi e migliorare i risultati scolastici. Si parlerà dei prerequisiti per capire la fisica, le sue definizioni e i suoi principi e come applicare le nozioni, per poi addentrarsi nella pratica della risoluzione dei problemi.",
  },
];

const LINKS = {
  ios: "https://www.apple.com/it/app-store/",
  android:
    "https://play.google.com/store/apps/details?id=com.takeflight.kairos.ai",
};

export { gender, LINKS, months, roles, work, years, WEBINARS };
