/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";

import { useParams } from "react-router-dom";
import { useAuthState } from "../providers/AuthProvider";
import { useTranslation } from "react-i18next";
import useAnalytics from "../hooks/useAnalytics";
import ReactMarkdown from "react-markdown";

import { Col, Divider, Modal, Row, Typography } from "antd";

import client from "../kairosClient";
import { Loading } from "../pages";
import BaseButton from "./BaseButton";
import { useHistory } from "react-router";

const EbookDetail = () => {
  const { Title } = Typography;
  const { id } = useParams();
  const { t } = useTranslation();
  const { token } = useAuthState();
  const { logEbookView } = useAnalytics();
  const [showModal, setShowModal] = React.useState(false);

  const [ebook, setEbook] = React.useState(null);

  const history = useHistory();

  React.useEffect(() => {
    const readEbook = (ebookId, accessToken) => {
      client.ebook
        .get(ebookId, accessToken)
        .then((res) => res.json())
        .then((res) => {
          if (res.eCode) {
            console.log("EbookDetail.useEffect.readEbook error", res);
          } else {
            console.log("EbookDetail.useEffect.readEbook success", res);
            setEbook(res);
            logEbookView(id);
          }
        });
    };

    if (id !== null) {
      readEbook(id, token);
    }
  }, []);

  if (ebook === null) {
    return <Loading />;
  }

  const handleLink = () => {
    if (ebook.links && ebook.links.length > 0) {
      window.open(ebook.links[0], "_blank");
    } else {
      setShowModal(true);
    }
  };

  const handleClose = () => history.push("/ebook");

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="container">
        <div
          className="card-style p-0 card-border"
          style={{
            margin: "auto",
            borderRadius: "20px",
            backgroundColor: "#fff",
          }}
        >
          <div className="container-title-book">
            <p
              style={{
                fontSize: "28px",
                textTransform: "uppercase",
                marginBottom: "0px",
              }}
            >
              <strong>{ebook.title}</strong>
            </p>
          </div>
          <Row justify="center" style={{ padding: "10px 0px" }}>
            <Col span={20}>
              <Title
                level={3}
                style={{ textAlign: "center" }}
                className="title-blue"
              >
                {t("label_author").toUpperCase()}
              </Title>
              <p style={{ fontSize: "20px" }}>
                {ebook.author}
                <br />
                {ebook.authorBio}
              </p>
              <Title
                level={3}
                style={{ textAlign: "center" }}
                className="title-blue"
              >
                {t("label_synopsis").toUpperCase()}
              </Title>
              <p style={{ fontSize: "18px" }}>{ebook.synopsis}</p>
              <Title
                className="title-blue"
                level={3}
                style={{ textAlign: "center", textTransform: "uppercase" }}
              >
                {ebook.previewTitle}
              </Title>
              <ReactMarkdown style={{ fontSize: "18px" }}>
                {ebook.preview}
              </ReactMarkdown>
            </Col>
          </Row>
          <Divider style={{ border: "2px solid #004AAC" }} />
          <Row justify="center" style={{ padding: "10px 0px" }}>
            <Col xl={{ span: 5 }} xs={{ span: 13 }}>
              <BaseButton
                className="shadow-button"
                type="primary"
                block
                shape="round"
                size="large"
                onClick={handleClose}
              >
                {t("close").toUpperCase()}
              </BaseButton>
            </Col>
            <Col xl={{ span: 5, offset: 1 }} xs={{ span: 13 }}>
              <BaseButton
                className="shadow-button"
                type="primary"
                block
                shape="round"
                size="large"
                onClick={handleLink}
              >
                {t("label_buy_book").toUpperCase()}
              </BaseButton>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        visible={showModal}
        footer={null}
        closable={false}
        maskClosable={false}
        onCancel={closeModal}
        bodyStyle={{ backgroundColor: "#cbe1ff" }}
      >
        <div style={{ textAlign: "center", color: "#004AAC" }}>
          <Title level={4} style={{ color: "#004AAC", fontWeight: "bold" }}>
            {t("attention").toUpperCase()}
          </Title>
          <Divider style={{ color: "#004AAC", border: "1px solid " }}></Divider>
          <p style={{ fontSize: "20px" }}>{t("available_soon")}</p>
        </div>
        <Row justify="center">
          <Col span={6}>
            <BaseButton
              className="shadow-button"
              type="primary"
              block
              shape="round"
              size="large"
              style={{ marginBottom: "0px" }}
              onClick={closeModal}
            >
              {t("label_ok").toUpperCase()}
            </BaseButton>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default EbookDetail;
