/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Card, Col, Divider, Form, Input, Modal, Row, Typography } from "antd";

import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";

import Password from "../images/pass.png";
import { BaseButton } from "../components";

const RecoverPassword = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { recoverPassword } = useAuthState();
  const history = useHistory();

  const [showModal, setShowModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState(null);

  const handleRecoverPassword = async ({ email }) => {
    //console.log("RecoverPassword.handleChangePassword", email);
    await recoverPassword(email, onSuccess, onError);
  };

  const onSuccess = () => {
    //console.log("RecoverPassword.onSuccess");
    history.push("/recoverpasswordok");
  };

  const onError = (err) => {
    //console.log("RecoverPassword.onError", err);
    openModal(t(`error_${err.eCode}`));
  };

  const openModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage(null);
  };

  return (
    <React.Fragment>
      <Modal
        visible={showModal}
        footer={null}
        closable={false}
        maskClosable={false}
        onCancel={closeModal}
        bodyStyle={{ backgroundColor: "#cbe1ff" }}
      >
        <div style={{ textAlign: "center", color: "#004AAC" }}>
          <Title level={4} style={{ color: "#004AAC", fontWeight: "bold" }}>
            {t("label_verify_code").toUpperCase()}
          </Title>
          <Divider style={{ color: "#004AAC", border: "1px solid " }}></Divider>
          <p style={{ fontSize: "20px" }}>{modalMessage}</p>
        </div>
        <Row justify="center">
          <Col span={6}>
            <BaseButton
              className="shadow-button"
              type="primary"
              block
              shape="round"
              size="large"
              style={{ marginBottom: "0px" }}
              onClick={closeModal}
            >
              {t("label_ok").toUpperCase()}
            </BaseButton>
          </Col>
        </Row>
      </Modal>

      <div className="container">
        <div
          className="card-style p-0"
          style={{
            border: "0px solid",
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <Row align="middle" justify="center">
            <Col
              className="col-blue"
              md={{ span: 12 }}
              xs={{ span: 24 }}
              align="center"
              style={{
                minHeight: "600px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Password} alt="LoginImg" width="80%" height="80%" />
            </Col>
            <Col
              className="col-lightblue"
              md={{ span: 12 }}
              xs={{ span: 24 }}
              style={{
                minHeight: "600px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Title
                className="title-blue"
                level={2}
                style={{ textAlign: "center" }}
              >
                {t("label_recover_password").toUpperCase()}
              </Title>
              <Row justify="center">
                <Col span={18}>
                  <Form
                    size="large"
                    requiredMark="optional"
                    layout="vertical"
                    onFinish={handleRecoverPassword}
                  >
                    <Form.Item
                      name="email"
                      type="email"
                      rules={[
                        { required: true, message: t("message_insert_email") },
                        { type: "email", message: t("message_invalid_mail") },
                      ]}
                    >
                      <Input placeholder={t("label_email")} />
                    </Form.Item>
                    <Row justify="center">
                      <Col>
                        <Title level={5} className="title-blue">
                          <strong>{t("label_email_password")}</strong>
                        </Title>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 16 }}
                        md={{ span: 14 }}
                        xs={{ span: 20 }}
                      >
                        <BaseButton
                          type="primary"
                          shape="round"
                          htmlType="submit"
                          block
                        >
                          {t("label_request_link").toUpperCase()}
                        </BaseButton>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default RecoverPassword;
