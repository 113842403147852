import React, { useState } from "react";
import { Checkbox, Col, Form, Input, Modal, Row, Typography } from "antd";

import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";

import { BaseButton } from "../components";
import SignupImg from "../images/iscrizione.png";


const Signup = () => {
  const { t } = useTranslation();

  const { Title } = Typography;
  const { isLoading, signup, signupWithCode } = useAuthState();
  const [codec, setCodeC] = useState();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false)

  const [errorMessage, setErrorMessage] = React.useState(null);
  React.useEffect(() => {
    const codecompany = new URLSearchParams(window.location.search).get("c");
    setCodeC(codecompany);
  }, []);

  const handleSubmit = async ({ email, password, confirmPassword }) => {
    setErrorMessage(null);

    await signup(email, password, confirmPassword, codec, onSuccess, onError, true, () => {
      setShowModal(true)
    });
  };

  const handleSubmitWithCode = async ({ email, password, confirmPassword }) => {
    setErrorMessage(null);

    await signupWithCode(
      email,
      password,
      confirmPassword,
      codec,
      onSuccessWitchCode,
      onError,
      true,
      () => {
        setShowModal(true)
      });

  };

  const onSuccessWitchCode = () => {
    history.push("/registration_success");
  };

  const onSuccess = () => {
    history.push("/");
  };

  const onError = (err) => {
    // setErrorMessage(t(`error_${err.eCode}`));
    setShowModal(true)
  };

  return (
    <div className="container">
      <Modal
        visible={showModal}
        closable={true}
        onCancel={() => setShowModal(false)}
        title={<Row align="center">
          <Col style={{ fontSize: 22, color: '#004AAC', fontWeight: 'bold' }}>{t('attention')}</Col>
        </Row>}
        footer={
          <Row justify="center" align="middle" style={{ paddingHorizontal: 10 }}>
            <Col>
              <BaseButton
                className="shadow-button"
                type="primary"
                block
                shape="round"
                htmlType="button"
                style={{ height: 40, width: 205, borderWidth: 0, marginBottom: 0 }}
                onClick={() => { history.push("/login") }}
              >{t('login').toUpperCase()}</BaseButton>
              <BaseButton
                className="shadow-button"
                block
                shape="round"
                htmlType="button"
                style={{ height: 40, width: 205, borderWidth: 2, borderColor: "#004AAC", marginBottom: 0, color: "#004AAC" }}
                onClick={() => { history.push("/recoverpassword") }}
              >{t('label_new_password').toUpperCase()}</BaseButton>
            </Col>
          </Row>}>
        <p style={{ fontSize: 18, color: '#004AAC', fontWeight: 'bold' }}>{t('signup_error_auth0')}</p>
      </Modal>
      <Row
        align="middle"
        justify="center"
        className="card-style p-0"
        style={{
          border: "0px solid",
          margin: "auto",
          borderRadius: "20px",
        }}
      >
        <Col
          className="col-blue"
          md={{ span: 12 }}
          xs={{ span: 24 }}
          align="center"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img src={SignupImg} alt="LoginImg" width="80%" height="80%" />
        </Col>
        <Col className="col-lightblue" md={{ span: 12 }} xs={{ span: 24 }}>
          <Title
            className="title-blue"
            level={2}
            style={{ textAlign: "center" }}
          >
            {t("label_subscrition").toUpperCase()}
          </Title>
          <Row justify="center">
            <Col span={14}>
              <Form
                size="large"
                layout="vertical"
                onFinish={
                  codec && codec.length > 0
                    ? handleSubmitWithCode
                    : handleSubmit
                }
              >
                <p className="paragraph_form" style={{ textAlign: "left" }}>
                  <strong> {t("label_username").concat("*")} </strong>
                </p>
                <Form.Item
                  name="email"
                  type="email"
                  rules={[
                    { required: true, message: t("message_insert_username") },
                    { type: "email", message: t("message_invalid_mail") },
                  ]}
                >
                  <Input placeholder={t("label_email")} />
                </Form.Item>
                <p className="paragraph_form" style={{ textAlign: "left" }}>
                  <strong> {t("label_password").concat("*")} </strong>
                </p>
                <Form.Item
                  type="password"
                  name="password"
                  rules={[
                    { required: true, message: t("message_form_password") },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const pwdRegex =
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/gm;
                        if (pwdRegex.exec(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(t("message_invalid_password"));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder={t("label_password")} />
                </Form.Item>
                <p className="paragraph_form" style={{ textAlign: "left" }}>
                  <strong> {t("label_confirm_password").concat("*")} </strong>
                </p>
                <Form.Item
                  type="password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    { required: true, message: t("message_confirm_password") },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          t("message_invalid_confirm_password")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder={t("label_confirm_password")} />
                </Form.Item>
                <Form.Item
                  name="privacy-policy"
                  valuePropName="checked"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(t("message_invalid_privacy"));
                      },
                    }),
                  ]}
                >
                  <Checkbox>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("privacy_policy", {
                          interpolation: { escapeValue: false },
                        }),
                      }}
                    ></span>
                  </Checkbox>
                </Form.Item>
                {errorMessage && (
                  <div
                    style={{
                      backgroundColor: "#F5F5F5",
                      fontWeight: "500",
                      marginBottom: "20px",
                      padding: "12px 8px",
                      border: "2px solid #DD0000",
                      borderRadius: "8px",
                      textAlign: "center",
                    }}
                  >
                    {errorMessage}
                  </div>
                )}

                <Row justify="center">
                  <Col xl={{ span: 12 }} xs={{ span: 16 }}>
                    <BaseButton
                      className="shadow-button"
                      type="primary"
                      block
                      shape="round"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      {t("label_signup").toUpperCase()}
                    </BaseButton>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Signup;
