import React from "react";
import { Collapse, Col, notification, Row, Modal, Spin, Input } from "antd";
import { RightOutlined, LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { BaseButton, DataUser } from "../components";
import NewPassword from "./NewPassword";
import ChangeEmail from "./ChangeEmail";
import { useAuthState } from "../providers/AuthProvider";
import client from "../kairosClient";

const PersonalData = () => {
  const { t } = useTranslation();
  const { token, user, logout } = useAuthState();
  const { Panel } = Collapse;

  const [showModal, setShowModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [confirm, setConfirm] = React.useState('');
  const [working, setWorking] = React.useState(false);

  const handleSuccess = () =>
    notification.success({
      key: "newPassword",
      message: t("message_title_success"),
      description: t('label_change_data_success')
    });

  const handleError = (err) =>
    notification.error({
      key: "newPassword",
      message: t("error_notification_title"),
      description: t('label_change_data_error')
    });

  const deleteAccount = () => {
    setWorking(true);
    client.user.delete(user.id, token).then((res) => {
      setShowModal(false);
      setWorking(false);
      setConfirm('');
      if (res.ok)
        logout();
      else {
        setShowErrorModal(true);
      }
    });
  };

  return (
    <div className="container" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Collapse
        className="collapse-style"
        defaultActiveKey={['1']}
        expandIconPosition={"right"}
        expandIcon={({ isActive }) => (
          <RightOutlined
            style={{ fontSize: "30px", color: '#fff' }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        <Panel
          header={
            <Row justify="center">
              <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                <span style={{ fontSize: '28px', textTransform: 'uppercase', marginBottom: '0px', color: '#fff' }}>
                  <strong>{t('label_data')}</strong>
                </span>
              </Col>
            </Row>
          }
          key="1"
          style={{ backgroundColor: '#004AAC', borderRadius: '20px', borderBottom: '0px solid' }}
        >
          <DataUser onSuccess={handleSuccess} onError={handleError} buttonLabel={t('save').toUpperCase()} />
        </Panel>
      </Collapse>

      <Collapse
        className="collapse-style"
        expandIconPosition={"right"}
        expandIcon={({ isActive }) => (
          <RightOutlined
            style={{ fontSize: "30px", color: '#fff' }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        <Panel
          header={
            <Row justify="center">
              <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                <span style={{ fontSize: '28px', textTransform: 'uppercase', marginBottom: '0px', color: '#fff' }}>
                  <strong>{t('label_change_password')}</strong>
                </span>
              </Col>
            </Row>
          }
          key="2"
          style={{ backgroundColor: '#004AAC', borderRadius: '20px', borderBottom: '0px solid' }}
        >
          <NewPassword />
        </Panel>
      </Collapse>

      <Collapse
        className="collapse-style"
        expandIconPosition={"right"}
        expandIcon={({ isActive }) => (
          <RightOutlined
            style={{ fontSize: "30px", color: '#fff' }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        <Panel
          header={
            <Row justify="center">
              <Col xl={{ span: 20 }} xs={{ span: 22 }}>
                <span style={{ fontSize: '28px', textTransform: 'uppercase', color: '#fff' }}>
                  <strong>{t("label_change_email")}</strong>
                </span>
              </Col>
            </Row>
          }
          key="2"
          style={{ backgroundColor: '#004AAC', borderRadius: '20px', borderBottom: '0px solid' }}
        >
          <ChangeEmail />
        </Panel>
      </Collapse>

      <Row justify="center" style={{ width: '100%', marginTop: 10 }}>
        <Col xl={{ span: 4 }} xs={{ span: 10 }}>
          <BaseButton
            className="shadow-button"
            type="primary"
            block
            shape="round"
            htmlType="button"
            style={{ backgroundColor: '#860303', height: 40, borderWidth: 0 }}
            onClick={() => setShowModal(true)}
          >
            {t("label_delete").toUpperCase()}
          </BaseButton>
        </Col>
      </Row>
      <Modal
        visible={showModal}
        closable={false}
        title={<Row align="center">
          <Col style={{ fontSize: 22, color: '#004AAC', fontWeight: 'bold' }}>{t('attention')}</Col>
        </Row>}
        footer={<Row justify="space-between" align="middle" style={{ paddingHorizontal: 10 }}>
          <Col>
            <BaseButton
              className="shadow-button"
              type="primary"
              block
              shape="round"
              htmlType="button"
              style={{ height: 40, width: 105 }}
              onClick={() => {
                document.getElementById('delete_input').value = '';
                setConfirm('');
                setShowModal(false);
              }}
            >{t('delete_cancel')}</BaseButton>
          </Col>
          <Col>
            <BaseButton
              className="shadow-button"
              type="primary"
              block
              shape="round"
              htmlType="button"
              disabled={confirm !== t('delete_confirm')}
              style={{ backgroundColor: '#860303', height: 40, width: 105, borderWidth: 0 }}
              onClick={deleteAccount}
            >{working ? <Spin indicator={<LoadingOutlined style={{ color: 'white' }} />} /> : t('delete_proceed')}</BaseButton>
          </Col>
        </Row>}>
        <p style={{ fontSize: 18, color: '#004AAC', fontWeight: 'bold' }}>{t('delete_text_1', { confirm: t('delete_confirm') })}</p>
        <p style={{ fontSize: 18, color: '#860303', fontWeight: 'bold', textDecorationLine: 'underline' }}>{t('delete_text_2')}</p>
        <Input id="delete_input" placeholder={t("delete_confirm_placeholder")} onChange={(e) => setConfirm(e.target.value)} />
      </Modal>
      <Modal
        visible={showErrorModal}
        closable={true}
        title={<Row align="center">
          <Col style={{ fontSize: 22, color: '#004AAC', fontWeight: 'bold' }}>{t('attention')}</Col>
        </Row>}
        footer={<Row justify="center" align="middle" style={{ paddingHorizontal: 10 }}>
          <Col>
            <BaseButton
              className="shadow-button"
              type="primary"
              block
              shape="round"
              htmlType="button"
              style={{ height: 40, width: 105, borderWidth: 0, marginBottom: 0 }}
              onClick={() => setShowErrorModal(false)}
            >{t('label_ok').toUpperCase()}</BaseButton>
          </Col>
        </Row>}>
        <p style={{ fontSize: 18, color: '#004AAC', fontWeight: 'bold' }}>{t('delete_error')}</p>
      </Modal>
    </div>
  );
};
export default PersonalData;
