/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";

import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import client from "../kairosClient";

import { BaseButton } from "../components";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const CompanySignup = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const history = useHistory();
  const { token } = useAuthState();
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [school, setSchool] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [code, setCode] = useState(null);

  const handleSubmit = (values) => {
    setErrorMessage(null);
    console.log("Submit company values", values);
    const {
      company_name,
      vat_number,
      city,
      address,
      companyType,
      logo,
      email,
      link,
      note,
    } = values;

    const data = {
      name: company_name,
      vatNumber: vat_number,
      city: city,
      address: address,
      email: email,
      typeId: 2, //id aziende
      logo: file[0].url,
      link: link,
      note: note,
    };

    console.log("Adesso chiamo la submit con -->", data);
    client.company
      .signup(data, token)
      .then((res) => {
        console.log("Primo then della promise", res);
        return res.json();
      })
      .then((json) => {
        console.log("Secondo then della promise", json);
        if (json.eCode) {
          notification.error({
            key: "newSignup",
            message: t("error_notification_title"),
            description: t("error_undefined"),
          });
        } else {
          notification.success({
            key: "companySignup",
            message: t("message_title_success"),
            description: t("label_insert_company_success"),
          });
          setCode(json);
          setShowModal(true);
        }
      })
      .catch((error) =>
        console.error("Errore imprevisto chiamando il submit", error)
      )
      .finally(() => console.log("Finalmente ho finito con la submit"));

    console.log("Dopo aver chiamato la submit");
  };

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const dummyRequest = ({ file, onSuccess }, multiple, limit) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile([
        {
          uid: new Date().getMilliseconds(),
          name: file.name,
          status: "done",
          url: reader.result,
          file: file,
          b64Cover: null,
        },
      ]);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  let label_change = "label_vat_number";

  const handleChange = (value) => {
    setSchool(value);
    if (value === 1) {
      setSchool(true);
    } else {
      setSchool(false);
    }

    console.log("form change", label_change);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        visible={showModal}
        footer={null}
        closable={false}
        maskClosable={false}
        onCancel={closeModal}
        centered
        bodyStyle={{ backgroundColor: "#cbe1ff" }}
      >
        <div style={{ textAlign: "center", color: "#004AAC" }}>
          <Title level={4} style={{ color: "#004AAC", fontWeight: "bold" }}>
            {t("company_success").toUpperCase()}
          </Title>
          <Divider style={{ color: "#004AAC", border: "1px solid " }}></Divider>
          <p style={{ fontSize: "20px" }}>
            {t("id_company_singup", { code: code })}
          </p>
        </div>
        <Row justify="center">
          <Col span={6}>
            <BaseButton
              className="shadow-button"
              type="primary"
              block
              shape="round"
              size="large"
              style={{ marginBottom: "0px" }}
              onClick={() => history.push("/admin")}
            >
              {t("label_ok").toUpperCase()}
            </BaseButton>
          </Col>
        </Row>
      </Modal>

      <div className="container">
        <Form size="large" layout="vertical" onFinish={handleSubmit}>
          <Row
            align="middle"
            justify="center"
            className="card-style p-0"
            style={{
              border: "0px solid",
              margin: "auto",
              borderRadius: "20px",
            }}
          >
            <Col
              className="col-blue"
              md={{ span: 12 }}
              xs={{ span: 24 }}
              align="center"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "20px 0px",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Title
                className="title-white"
                level={2}
                style={{ textAlign: "center" }}
              >
                {t("logo").toUpperCase()}
              </Title>
              <p
                style={{
                  fontSize: "20px",
                  color: "#fff",
                  padding: "0px 30px",
                }}
              >
                {t("info_upload_image")}
              </p>
              <Form.Item
                name="logo"
                //type="file"
                accept="image/*"
                getvalueprop="fileList"
                getValueFromEvent={(e) => normFile(e)}
                rules={[
                  { required: true, message: t("message_form_logo") },
                  ({ getFieldValue }) => ({
                    validator(_, file) {
                      //controllo se è vuoto o se la dimensione del file è 1 mb
                      if (!file || file[0].size <= 1048576) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(t("message_invalid_size_logo"));
                      }
                    },
                  }),
                ]}
                style={{ width: "220px" }}
              >
                <Upload
                  name="logo"
                  listType="picture-card"
                  accept="image/png, image/jpg, image/jpeg"
                  multiple={false}
                  maxCount={1}
                  customRequest={dummyRequest}
                  showUploadList={false}
                  fileList={file}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        backgroundColor: "#ffffff",
                        width: "220px",
                        height: "220px",
                        marginBottom: "80px",
                        border: "2px solid #cbe1ff",
                        borderRadius: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {file ? (
                        <img
                          src={file[0].url}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "20px",
                            objectFit: "contain",
                          }}
                          alt="logo"
                        />
                      ) : (
                        <UploadOutlined
                          style={{ fontSize: "120px", color: "#cbe1ff" }}
                        />
                      )}
                    </div>

                    <BaseButton
                      className="btn-upload-image"
                      type="primary"
                      block
                      shape="round"
                    >
                      {t("upload_image").toUpperCase()}
                    </BaseButton>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
            <Col className="col-lightblue" md={{ span: 12 }} xs={{ span: 24 }}>
              <Title
                className="title-blue"
                level={2}
                style={{ textAlign: "center", marginBottom: "0px" }}
              >
                {t("label_company_school_subscrition").toUpperCase()}
              </Title>
              <Row justify="center">
                <Col span={16}>
                  <p className="paragraph_form" style={{ textAlign: "left" }}>
                    <strong> {t("label_company_name").concat("*")} </strong>
                  </p>
                  <Form.Item
                    name="company_name"
                    rules={[
                      {
                        required: true,
                        message: t("message_form_company_name"),
                      },
                    ]}
                  >
                    <Input placeholder={t("label_company_name")} />
                  </Form.Item>
                  <p className="paragraph_form" style={{ textAlign: "left" }}>
                    <strong> {t("label_url_company").concat("*")} </strong>
                  </p>
                  <Form.Item
                    name="link"
                    rules={[
                      { required: true, message: t("message_form_url_name") },
                      {
                        type: "url",
                        message: t("not_valid_url"),
                        warningOnly: true,
                      },
                    ]}
                  >
                    <Input placeholder={t("label_url_company")} />
                  </Form.Item>
                  <p className="paragraph_form" style={{ textAlign: "left" }}>
                    <strong>{t("label_vat_number").concat("*")}</strong>
                  </p>
                  <Form.Item
                    name="vat_number"
                    rules={[
                      {
                        required: true,
                        message: t("message_form_vat_number"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          //controllo scuola vuoto e se è valorizzato

                          if (!value || value.length === 11) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              t("message_invalid_vat_number")
                            );
                          }
                        },
                      }),
                    ]}
                  >
                    <Input maxLength={11} placeholder={t("label_vat_number")} />
                  </Form.Item>
                  <p className="paragraph_form" style={{ textAlign: "left" }}>
                    <strong>{t("label_email").concat("*")}</strong>
                  </p>
                  <Form.Item
                    name="email"
                    type="email"
                    rules={[
                      { required: true, message: t("message_insert_email") },
                      { type: "email", message: t("message_invalid_mail") },
                    ]}
                  >
                    <Input placeholder={t("label_email")} />
                  </Form.Item>

                  <p className="paragraph_form" style={{ textAlign: "left" }}>
                    <strong>{t("label_city").concat("*")}</strong>
                  </p>
                  <Form.Item
                    name="city"
                    rules={[
                      { required: true, message: t("message_form_city") },
                    ]}
                  >
                    <Input placeholder={t("label_city")} />
                  </Form.Item>
                  <p className="paragraph_form" style={{ textAlign: "left" }}>
                    <strong>{t("label_address").concat("*")}</strong>
                  </p>
                  <Form.Item
                    name="address"
                    rules={[
                      { required: true, message: t("message_form_address") },
                    ]}
                  >
                    <Input placeholder={t("label_address")} />
                  </Form.Item>

                  <p className="paragraph_form" style={{ textAlign: "left" }}>
                    <strong>{t("label_note")}</strong>
                  </p>
                  <Form.Item name="note">
                    <TextArea placeholder={t("label_note")} rows={4} />
                  </Form.Item>

                  {errorMessage && (
                    <div
                      style={{
                        backgroundColor: "#F5F5F5",
                        fontWeight: "500",
                        marginBottom: "20px",
                        padding: "12px 8px",
                        border: "2px solid #860303",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <strong>{errorMessage}</strong>
                    </div>
                  )}

                  <Row justify="center">
                    <Col xl={{ span: 12 }} xs={{ span: 16 }}>
                      <BaseButton
                        className="shadow-button"
                        type="primary"
                        block
                        shape="round"
                        htmlType="submit"
                      >
                        {t("save").toUpperCase()}
                      </BaseButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
export default CompanySignup;
