/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import dayjs from "dayjs";
import { Card, Col, Divider, Progress, Row, Slider, Typography } from "antd";

import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";
import { useHistory } from "react-router";

import client from "../kairosClient";
import { BaseButton, DataUser } from "../components";
import { COLORS } from "../constants";
import User from "../images/graduate.png";

const Survey = () => {
  const utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const { t } = useTranslation();
  const history = useHistory();

  const { Title } = Typography;
  const { setIsDoingSurvey, refresh, surveyProgress, token, user } =
    useAuthState();

  const [showForm, setShowForm] = React.useState(null);
  const [survey, setSurvey] = React.useState(null);
  const [currentScore, setCurrentScore] = React.useState(0);
  const [isSliderDisabled, setIsSliderDisabled] = React.useState(false);

  React.useEffect(() => {
    if (showForm === null) {
      setShowForm(user.totAnswer === 0);
    }
    setIsDoingSurvey(true);

    return () => setIsDoingSurvey(false);
  }, []);

  React.useEffect(() => {
    const createInstance = async () => {
      const res = await client.survey.newInstance(token);

      if (res.eCode) {
        console.error("Survey.useEffect[showForm].createInstance error", res); //ok
        //showError(res);
        return null;
      }
      const instance = await res.json();
      //Aggiorno dati utente
      refresh();
      return instance.id;
    };

    const isProfileExpired = (date) => {
      const now = dayjs.utc().startOf("day");
      const profileDate = dayjs.utc(date).startOf("day");
      return now.isAfter(profileDate);
    };

    const setup = async () => {
      //console.log('Survey.useEfect[showForm]', showForm);
      if (showForm === null) {
        return;
      }

      if (!showForm) {
        let instanceId = user.surveyInstanceId;
        //Verifico se è il primo test fatto oppure
        //se l'attuale profilo è scaduto
        if (
          instanceId === null ||
          (user.completed !== null && isProfileExpired(!user.expire))
        ) {
          instanceId = await createInstance();
          //const action = user.surveyInstanceId ? ACTIONS.REDO : ACTIONS.NEW;
          //const status = instanceId ? STATUS.SUCCESS : STATUS.ERROR;
          //logSurvey(action, status, instanceId);
          if (instanceId === null) {
            return;
          }
        }

        client.survey
          .getNextQuestion(instanceId, token)
          .then((res) => res.json())
          .then((res) => {
            if (res === null) {
              console.log(
                "Survey.getNextQuestion survey instance completed, non dovrei arrivare qua"
              );
            } else if (res.eCode) {
              console.error("Survey.getNextQuestion error: ", res); //ok
              //showError(res);
            } else {
              //logSurvey(
              //  ACTIONS.READ,
              //  STATUS.SUCCESS,
              //  res.instanceId,
              //  res.questions[0].id
              //);
              setSurvey(res);
            }
          })
          .catch((err) => {
            err["eCode"] = 999;
            //showError(err);
            console.error("Survey.getNextQuestion unpredictable error", err); //ok
          });
      }
    };

    setup();
  }, [showForm]);

  const handleAnswerSubmit = (score) => {
    console.log("Survey.handleAnswerSubmit score", score);
    setCurrentScore(score);
    let newSurvey = null;
    client.survey
      .setQuestionAnswer(
        survey.instanceId,
        survey.questions[0].id,
        score,
        token
      )
      .then((res) => res.json())
      .then((res) => {
        if (res === null) {
          console.log("Survey.sendAnswer survey completed");
          //logSurvey(ACTIONS.COMPLETE, STATUS.SUCCESS, survey.instanceId);
          refresh();
          history.push("surveyresult");
        } else if (res.eCode) {
          //logSurvey(
          //  ACTIONS.SUBMIT,
          //  STATUS.ERROR,
          //  survey.instanceId,
          //  survey.questions[0].id,
          //);
          //showError(res);
          console.error("Survey.sendAnswer error", res); //ok
        } else {
          //logSurvey(
          //  ACTIONS.SUBMIT,
          //  STATUS.SUCCESS,
          //  survey.instanceId,
          //  survey.questions[0].id,
          //);
          //Update user data in AuthContext
          refresh();
          console.log("Survey.sendAnswer response", res);
          newSurvey = res;
        }
      })
      .catch((err) => {
        err["eCode"] = 999;
        //showError(err);
        console.error("survey.sendAnswer unpredictable error", err); //ok
      })
      .finally(() => {
        //logSurvey(
        //  ACTIONS.READ,
        //  STATUS.SUCCESS,
        //  survey.instanceId,
        //  survey.questions[0].id,
        //);
        //setSending(false);
        //fadeIn(newSurvey);
        setSurvey(newSurvey);
        setIsSliderDisabled(true);
        setTimeout(() => {
          setCurrentScore(0);
          setIsSliderDisabled(false);
        }, 200);
      });
  };

  const handlePause = () => {
    history.push("/surveyresult");
  };

  if (showForm) {
    return (
      <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="card-style p-0 card-border"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "20px",
            padding: "20px 16px",
            boxShadow: "none",
          }}
        >
          <Title
            level={2}
            className="title-blue"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            {t("label_about_you").toUpperCase()}
          </Title>
          <div style={{ textAlign: "center" }}>
            <img src={User} alt="user" width="11%" />
          </div>
          <DataUser
            onSuccess={() => setShowForm(false)}
            onError={(err) => console.error(err)}
            buttonLabel={t("forward").toUpperCase()}
          >
            <Title
              level={2}
              className="title-blue"
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              {t("label_survey_howto").toUpperCase()}
            </Title>
            <div
              className="title-black message"
              dangerouslySetInnerHTML={{
                __html: t("message_survey_howto", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></div>
          </DataUser>
        </div>
      </div>
    );
  }

  if (survey) {
    const marks = {
      0: 0,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
      9: 9,
      10: 10,
    };

    return (
      <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="card-style p-0 card-border"
          style={{
            margin: "auto",
            borderRadius: "20px",
            backgroundColor: "#fff",
          }}
        >
          <div
            className="title-white"
            style={{
              textAlign: "center",
              backgroundColor: "#004AAC",
              padding: "20px 0px 10px",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            <span style={{ fontSize: "28px" }}>
              <strong>{t("test").toUpperCase()}</strong>
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "24px",
              }}
            >
              <strong>{`${surveyProgress()}%`}</strong>
            </div>
          </div>
          <Row justify="center">
            <Col span={24}>
              <Progress
                className="no-border-progress"
                strokeLinecap="butt"
                strokeColor={COLORS.primary}
                trailColor={"#CBE1FF"}
                strokeWidth={10}
                percent={surveyProgress()}
                showInfo={false}
                style={{ marginTop: "-10px" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div>
            </Col>
          </Row>

          <Row
            className="container-style"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Col
              xl={{ span: 20 }}
              xs={{ span: 24 }}
              style={{ padding: "0px 40px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "100px",
                }}
              >
                <p
                  style={{
                    fontSize: "100px",
                    color: "#cbe1ff",
                    margin: "-30px 0px",
                  }}
                >
                  <strong>{survey.questions[0].number}</strong>
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    minHeight: "90px",
                  }}
                >
                  {survey.questions[0].text}
                </p>
              </div>
              <Slider
                disabled={isSliderDisabled}
                value={currentScore}
                className="slide-slider"
                dots
                min={0}
                max={10}
                marks={marks}
                onChange={(value) => setCurrentScore(value)}
                onAfterChange={handleAnswerSubmit}
              />
              <p
                className="title-grey"
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  marginTop: "60px",
                }}
              >
                {t("message_survey_answer_instruction")}
              </p>
            </Col>
          </Row>
          <Divider style={{ border: "1px solid #004AAC" }} />
          <Row justify="center" style={{ padding: "10px 0px" }}>
            <Col>
              <BaseButton
                className="shadow-button"
                type="primary"
                shape="round"
                onClick={handlePause}
                size="large"
              >
                {t("pause").toUpperCase()}
              </BaseButton>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  return null;
};
export default Survey;
