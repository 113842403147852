/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BaseButton } from "../components";
import { Loading } from "../pages";
import { RightOutlined } from "@ant-design/icons";
import { Collapse, Col, Row, Typography } from "antd";
import client from "../kairosClient";
import { useAuthState } from "../providers/AuthProvider";

const RegistrationCompany = () => {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const { Title } = Typography;
  const [isLoading, setLoading] = React.useState(false);
  const history = useHistory();
  const { token } = useAuthState();
  const [dati, setDati] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    client.company
      .getAll(token)
      .then((res) => res.json())
      .then((res) => {
        console.log("Analytics.useEffect[] instances", res);
        setDati(res);
      })
      .finally(() => setLoading(false));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        className="collapse-style"
        style={{
          backgroundColor: "#FFF",
          borderRadius: "10px",
          padding: "0px 20px",
          marginBottom: "20px",
          minWidth: "1240px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px 0px",
            borderBottom: "2px solid #E3EEF3",
          }}
        >
          {/* <form
            id="surveyForm"
             onSubmit={handleSubmit}
          >
            <input id="exportType" type="hidden" value="" />
            <div>
              <div
                style={{
                  display: "grid",
                  gap: "25px",
                  gridTemplateColumns: "20px 200px 20px 200px 80px 150px",
                  alignItems: "center",
                }}
              >
                <span className="date-form">{t("label_from")}</span>
                <input
                  id="dateFrom"
                  name="dateForm"
                  style={{ border: "0px", borderBottom: "2px solid #004AAC" }}
                  type="date"
                  value={search?.from}
              onInput={(e) => {
                const value = e.target.value;
                setSearch((prev) => ({ ...prev, from: value }));
              }} 
                />
                <span className="date-form">{t("label_to")}</span>
                <input
                  id="dateTo"
                  style={{ border: "0px", borderBottom: "2px solid #004AAC" }}
                  type="date"
                   value={search?.to}
              onInput={(e) => {
                const value = e.target.value;
                setSearch((prev) => ({ ...prev, to: value }));
              }} 
                />

                <SearchOutlined
                  style={{
                    cursor: "pointer",
                    fontSize: "30px",
                    color: "#004AAC",
                  }}  onClick={handleSubmit}
                />
              </div>
            </div>
          </form>*/}
          <div>
            <BaseButton
              className="shadow-button"
              type="primary"
              shape="round"
              htmlType="button"
              onClick={() => history.push("/companysignup")}
              style={{ marginBottom: "0px" }}
            >
              <span>{t("label_add").toUpperCase()}</span>
            </BaseButton>
          </div>
        </div>
        <div>
          <Row justify="center">
            <Col xl={{ span: 24 }} xs={{ span: 22 }}>
              <div
                style={{
                  margin: "20px 40px 0px 40px",
                  padding: "0px 16px",
                  display: "grid",
                  gridTemplateColumns: "1fr 360px 250px 100px",
                  columnGap: "24px",
                  fontSize: "20px",
                  textTransform: "uppercase",
                  color: "#004AAC",
                }}
              >
                <strong>{t("label_company_name")}</strong>
                <strong>{t("label_email")}</strong>
                <strong>{t("label_code")}</strong>
                <strong>{t("label_select_company_type")}</strong>
              </div>
            </Col>
          </Row>
          {dati.length > 0 ? (
            dati.map((d) => (
              <Collapse
                key={d.id}
                style={{ width: "100%" }}
                className="collapse-style"
                defaultActiveKey={["1"]}
                expandIconPosition={"right"}
                expandIcon={({ isActive }) => (
                  <RightOutlined
                    style={{ fontSize: "30px", color: "#fff" }}
                    rotate={isActive ? 90 : 0}
                  />
                )}
              >
                <Panel
                  style={{
                    backgroundColor: "#004AAC",
                    borderRadius: "10px",
                    borderBottom: "0px solid",
                  }}
                  header={
                    <Row justify="center">
                      <Col xl={{ span: 24 }} xs={{ span: 22 }}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 360px 250px 100px",
                            columnGap: "24px",
                            fontSize: "20px",
                            margin: "0px 16px 0px 40px",

                            color: "#fff",
                          }}
                        >
                          <span style={{ textTransform: "uppercase" }}>
                            {d.name}
                          </span>
                          <span>{d.email}</span>
                          <span>{d.code}</span>
                          <span style={{ textTransform: "uppercase" }}>
                            {d.typeId === 1
                              ? t("school")
                              : t("select_form_company_type_company")}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  }
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 360px 250px 100px",
                      columnGap: "20px",
                      fontSize: "18px",
                      margin: "0px 40px 0px 40px",
                    }}
                  >
                    <div>
                      <strong>{t("label_address").concat(": ")}</strong>
                      {d.address}
                    </div>
                    <div>
                      <strong>{t("label_city").concat(": ")}</strong>
                      {d.city}
                    </div>
                    <div>
                      <strong>
                        {d.typeId === 1
                          ? `${t("abbr_mechanographic_code")}`.concat(": ")
                          : `${t("abbr_vat_number")}`.concat(": ")}
                      </strong>
                      <span style={{ textTransform: "uppercase" }}>
                        {d.vatNumber}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "40px 1fr 70px",
                      columnGap: "20px",
                      fontSize: "18px",
                      margin: "0px 40px",
                    }}
                  ></div>
                </Panel>
              </Collapse>
            ))
          ) : (
            <Title
              level={3}
              style={{ textAlign: "center", fontWeight: "bold" }}
              className="title-blue"
            >
              {t("no_search_result").toUpperCase()}
            </Title>
          )}
        </div>
      </div>
    </div>
  );
};
export default RegistrationCompany;
