import React from "react";
import { Button, Row, Col } from "antd";
import { CarouselWelcome } from "../components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthState } from "../providers/AuthProvider";

const Welcome = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isProfileExpired, user } = useAuthState();

  const handleSurvey = () => {
    history.push(
      user.profileId && !isProfileExpired() ? "/profile" : "/survey"
    );
  };

  return (
    <>
      <Row style={{ padding: "60px 0px" }} justify="center">
        <Col
          xl={{ span: 8 }}
          md={{span: 10}}
          xs={{ span: 20 }}
          align="center"
          style={{ marginBottom: "30px" }}
        >
          <CarouselWelcome />
        </Col>
        <Col
          xl={{ span: 9, offset: 1 }}
          md={{ span: 10, offset: 1 }}
          xs={{ span: 20 }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="container-img">
            <p
              style={{
                fontSize: "32px",
                lineHeight: "1",
                marginBottom: "0px",
                textAlign: "center",
                maxWidth:'500px',
                color:'#ffffff',
              }}
            >
              <strong>{t("label_welcome_education").toUpperCase()}</strong>
            </p>
          </div>
          <div className="container-p-welcome">
            <p style={{ fontSize: "26px", fontWeight: "500", color: "#ffffff" }}>
              {t("label_metodo_kairos")}
            </p>
          </div>

          <Row>
            <Col>
              <Button
                size="large"
                shape="round"
                className="btn-test"
                onClick={() => handleSurvey()}
              >
                <span style={{ textTransform: "uppercase" }}>
                  <strong>
                    {t(
                      user.totAnswer === 0
                        ? "home_survey"
                        : isProfileExpired()
                        ? "home_survey_redo"
                        : user.profileId
                        ? "label_profile"
                        : "home_continue_survey"
                    )}
                  </strong>
                </span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Welcome;
